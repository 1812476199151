@font-face {
    font-family: 'Tahoma';
    src: url('assets/fonts/Tahoma/Tahoma-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Tahoma';
    src: url('assets/fonts/Tahoma/Tahoma-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/* Roboto */
@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/* Montserrat */

@font-face {
  font-family: 'Montserrat';
  src: url('assets/fonts/Montserrat/Montserrat-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/fonts/Montserrat/Montserrat-Bold.ttf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/* Kolleftkit*/

@font-face {
  font-family: 'Kollektif';
  src: url('assets/fonts/Kollektif/Kollektif.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Kollektif';
  src: url('assets/fonts/Kollektif/Kollektif-Bold.ttf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/* Lato */

@font-face {
  font-family: 'Lato';
  src: url(assets/fonts/Lato/Lato-Regular.ttf);
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url(assets/fonts/Lato/Lato-Bold.ttf);
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@import "react-datepicker/dist/react-datepicker.css";
@import 'react-toastify/dist/ReactToastify.css';
@import 'react-image-lightbox/style.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

/* This styles only working outside @layer */
.swal2-icon.swal2-success {
  @apply text-yellow-500 border-yellow-500 !important;
}
.swal2-success-ring {
  border-color: #dbcd844d !important;
}

.fc-h-event {
  @apply bg-transparent border-0 after:bg-transparent after:border-0 active:focus:bg-transparent active:focus:border-0 !important;
}
.fc-toolbar-title, .fc-col-header-cell-cushion,
.react-datepicker__current-month, .react-datepicker__day-name {
  @apply first-letter:capitalize !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  @apply hidden !important;
}

.ril__closeButton {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=) no-repeat center;
}

.ril__zoomOutButton {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PC9nPjxjaXJjbGUgY3g9IjEyIiBjeT0iOCIgcj0iNyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=) no-repeat center;
}

.ril__zoomInButton {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PHBhdGggZD0iTTEyIDV2NiIvPjwvZz48Y2lyY2xlIGN4PSIxMiIgY3k9IjgiIHI9IjciIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+) no-repeat center;
}

.ril__navButtonNext {
    background: rgba(0, 0, 0, 0.2) url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+) no-repeat center;
}

.ril__navButtonPrev {
    background: rgba(0, 0, 0, 0.2) url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==) no-repeat center;
}

.cm-table tbody tr {
  @apply divide-x divide-yellow-900/25 !important;
}

@layer base {
  ol li {
    @apply list-decimal;
  }

  ul ol {
    @apply list-disc;
  }
}

@layer components {
  [class^=swal2-success-line] {
    /* background-color: theme('colors.yellow.500') !important; */
    @apply bg-yellow-500 !important;
  }
  .divider {
    @apply border-b border-gray-500/60 !important;
  }
  .btn-yellow {
    @apply rounded-lg border border-transparent bg-yellow-500 py-3 px-4 text-sm font-bold text-black hover:bg-yellow-400 active:focus:outline-none active:focus:ring-2 active:focus:bg-yellow-500 active:focus:ring-yellow-500 active:focus:ring-offset-2 disabled:text-gray-500 disabled:bg-yellow-200 disabled:hover:bg-yellow-200 disabled:cursor-auto cursor-pointer flex items-center justify-center;
  }
  .btn-black {
    @apply rounded-lg border border-transparent bg-gray-700 py-3 px-4 text-sm font-bold text-gray-50 hover:bg-gray-800 active:focus:outline-none active:focus:ring-2 active:focus:bg-gray-900 active:focus:ring-gray-900 active:focus:ring-offset-2 cursor-pointer flex items-center justify-center;
  }
  .btn-gray {
    @apply rounded-lg border border-transparent bg-gray-200 py-3 px-4 text-sm font-bold text-black hover:bg-gray-300 active:focus:outline-none active:focus:ring-2 active:focus:bg-gray-400 active:focus:ring-gray-400 active:focus:ring-offset-2 disabled:text-gray-400 disabled:cursor-default disabled:hover:bg-gray-200 cursor-pointer flex items-center justify-center;
  }
  .btn-white {
    @apply rounded-lg border border-transparent bg-white py-3 px-4 text-sm font-bold text-black hover:bg-gray-200 active:focus:outline-none active:focus:ring-2 active:focus:bg-gray-300 active:focus:ring-gray-300 active:focus:ring-offset-2 cursor-pointer flex items-center justify-center;
  }
  .input-yellow {
    @apply h-11 appearance-none rounded-lg border-[1px] border-gray-300 bg-gray-50 px-3 py-2 text-sm text-gray-900 focus:z-10 focus:border-yellow-500 focus:outline-none focus:ring-yellow-500 disabled:text-gray-500 disabled:border-gray-200;
  }
  .select-yellow {
    @apply input-yellow pr-8 disabled:text-gray-600 disabled:border-gray-200 disabled:bg-stone-200;
  }
}